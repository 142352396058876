import React, { ComponentType, PropsWithChildren } from "react";
import { GridLayout } from "../../common/types/Data/GridLayout";
import { WithGridLayout } from "./withGridLayout";

export const withSingleItemGridLayout = <TProps,>(Component: ComponentType<TProps & WithGridLayout>): ComponentType<TProps> => {
    const singleItemGridLayout: GridLayout = {
        gridId: "single",
        mobileGridTemplate: "1",
        tabletGridTemplate: "1",
        desktopGridTemplate: "1",
    };

    const WithSingleItemGridLayout: ComponentType<TProps> = (props: PropsWithChildren<TProps>) => {
        return <Component {...props} gridLayout={singleItemGridLayout} />;
    };

    return WithSingleItemGridLayout;
};
