import React, { ComponentType, PropsWithChildren } from "react";
import { moduleError404FetcherFactory } from "./Modules/moduleError404FetcherFactory";
import { WithModuleFetcher } from "./withModuleFetcher";

const withModuleError404FetcherFactory = () => {
    return <TProps,>(Component: ComponentType<TProps & WithModuleFetcher>): ComponentType<TProps> => {
        const ComponentWithModuleFetcher = (props: PropsWithChildren<TProps>) => {
            const moduleFetcher = moduleError404FetcherFactory();

            return <Component {...props} moduleFetcher={moduleFetcher} />;
        };

        return ComponentWithModuleFetcher;
    };
};

export const withModuleError404Fetcher = withModuleError404FetcherFactory();
