import { PopulatedContent } from "../../../common/types/Data/Content";
import { content404Error } from "./content404Error";
import { ModuleFetcher } from "./ModuleFetcher";

export const moduleError404FetcherFactory = (): ModuleFetcher => {
    const entities: PopulatedContent[] = [content404Error];

    return async (): Promise<PopulatedContent[]> => {
        return entities;
    };
};
