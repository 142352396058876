import React from "react";
import { ComponentErrorGrid } from "../components/Grid/ComponentErrorGrid";
import { Layout } from "../components/Layout";
import { Main } from "../components/Main";
import "../scss/index.scss";

const Index = () => {
    return (
        <Layout>
            <Main redirectMode={true} path={"/*"}>
                <ComponentErrorGrid />
            </Main>
        </Layout>
    );
};

export default Index;
